import React, { useEffect, useState } from "react";
import Head from "next/head";
import { ConfigProvider, Layout, Tooltip } from "antd";
import {
  faArrowRightFromBracket,
  faBell,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import Collapsible from "./category/Collapsible";
import { signOut, useSession } from "next-auth/react";
import nProgress from "nprogress";
import { useSwrFetcherWithAccessToken } from "@/functions/useSwrFetcherWithAccessToken";
import { GetCategoryDetail } from "@/functions/BackendApiUrl";
import useSWR from "swr";
import { Authorize } from "./Authorize";
import SearchResultNav from "./category/SearchResultNav";
import Link from "next/link";
import SearchChecklistNavBar from "./category/checklist/SearchChecklistNavBar";
import SearchChecklistResult from "./category/checklist/SearchChecklistResult";
import SearchFileNavBar from "./category/checklist/SearchFileNavBar";
import SearchFileResult from "./category/checklist/SearchFileResult";
import SearchSubCategoryNavBar from "./category/SearchSubCategoryNavBar";
import SearchSubCategoryResultNav from "./category/SearchSubCategoryResultNav";
import NotificationModal from "@/pages/Notification/NotificationModal";


const { Sider, Content } = Layout;

const sidebarBackgroundColor = "white";

interface Verse {
  id: string;
  title: string;
  description: string;
  chapterId: string;
  chapter: string;
  checklistId: string[];
  isUploadFile: boolean;
  uploadStatusId: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

interface Chapter {
  id: string;
  title: string;
  description: string;
  isUploadFile: boolean;
  secondSubCategories: Verse[];
}

interface CategoryDetailModel {
  title: string;
  firstSubCategories: Chapter[];
}

interface SidebarMenuModel {
  title: string;
  routePath: string;
  isOpen: boolean;
}

interface CategorySidebarItemsModel extends SidebarMenuModel {
  children: SidebarMenuModel[];
}

const CategoryLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [openAll, setOpenAll] = useState(false);
  const [toggledFromCollapseOrExpandAll, setToggledFromCollapseOrExpandAll] =
    useState(false);
  const [chaptersExpandedState, setChaptersExpandedState] =
    useState<boolean[]>();
  const router = useRouter();
  const { query } = router.query;
  const [routePath, setRoutePath] = useState<string>(query?.toString() ?? "");

  const [firstSubCategories, setFirstSubCategories] =
    useState<CategorySidebarItemsModel[]>();

  const categoryId = router.query["categoryId"]?.toString() ?? "";

  const { data: session, status } = useSession();
  const displayUserName = session?.user?.name;

  const swrFetcher = useSwrFetcherWithAccessToken();
  const { data } = useSWR<CategoryDetailModel>(
    GetCategoryDetail(categoryId),
    swrFetcher
  );
  const [showModal, setShowModal] = useState(false);
  const userRole = session?.user?.["role"][0];
  const [searchResults, setSearchResults] = useState([]); // Search Bar Result
  const [selectedChapterIndex, setSelectedChapterIndex] = useState<number>(0);
  const isAdmin = userRole === "Admin";
  const isCategoryPage = router.pathname === "/[categoryId]";
  const isSubCategoryPage = router.pathname === "/[categoryId]/[chapterId]";
  const isChecklistPage =
    router.pathname === "/[categoryId]/[chapterId]/[verseId]";
  const isVersePage =
    router.pathname === "/[categoryId]/[chapterId]/[verseId]/ChecklistFiles";
  const isMasterPage =
    router.pathname === "/[categoryId]/master/masterQuestion" ||
    "/[categoryId]/master/masterQuestion/masterQuestionId" ||
    "/[categoryId]/master/masterVendor/masterVendorId";

  const goToManageUserPage = () => {
    router.push("/ManageUser");
  };

  const receiveDataFromChild = (data) => {
    setRoutePath(data);
  };

  useEffect(() => {
    setRoutePath(query?.toString() ?? "");
    if (data) {
      const firstSubCategoriesItem: CategorySidebarItemsModel[] =
        data?.firstSubCategories.map((chapter) => {
          // map each verses to make a child menu from each chapters
          const currentChapterVerses: SidebarMenuModel[] =
            chapter.secondSubCategories.map((verse) => {
              return {
                title: verse.title,
                routePath: `/${router.query["categoryId"]}/${chapter.id}/${verse.id}`,
                isOpen: false,
              };
            });

          return {
            title: chapter.title,
            routePath: `/${router.query["categoryId"]}/${chapter.id}`,
            children: currentChapterVerses,
            isOpen: false,
          };
        });

      setFirstSubCategories(firstSubCategoriesItem);
    }
  }, [data, query, router.query]);
  // console.log("first category : ", firstSubCategories)
  useEffect(() => {
    if (firstSubCategories) {
      const itemsCollapseStateMap = firstSubCategories.map(() => false);

      const categoryIdFromUrl = router.query["categoryId"]?.toString() ?? "";
      const firstSubCategoryIdFromUrl =
        router.query["chapterId"]?.toString() ?? "";
      const secondSubCategoryIdFromUrl =
        router.query["verseId"]?.toString() ?? "";
      const selectedChapterIndex = firstSubCategories.findIndex(
        (chapter) =>
          chapter.routePath ===
          `/${categoryIdFromUrl}/${firstSubCategoryIdFromUrl}`
      );

      const shouldKeepFirstSubCategoryExpanded = !!secondSubCategoryIdFromUrl;
      setSelectedChapterIndex(selectedChapterIndex);

      itemsCollapseStateMap[selectedChapterIndex] =
        shouldKeepFirstSubCategoryExpanded;
      setChaptersExpandedState(itemsCollapseStateMap);
    }
  }, [firstSubCategories, router.query]);

  function changeCollapseStatusByIndex(index: number, state: boolean) {
    const tempStateMap = chaptersExpandedState;
    if (tempStateMap) {
      tempStateMap[index] = state;
      setChaptersExpandedState(tempStateMap);
    }
  }

  function resetToggleFromButtonState() {
    setToggledFromCollapseOrExpandAll(false);
  }

  function handleExpandOrCollapseAll() {
    setToggledFromCollapseOrExpandAll(true);
    // Check if chaptersExpandedState is defined before using it
    if (chaptersExpandedState) {
      // Check if all chapters are expanded
      const isAllExpanded = chaptersExpandedState.every(
        (state) => state === true
      );
      // Update the state based on the current state
      setChaptersExpandedState(chaptersExpandedState.map(() => !isAllExpanded));
      // Update the open state based on the current state
      setOpenAll(!isAllExpanded);
    }
  }
  const handleLogout = () => {
    if (status === "authenticated") {
      nProgress.start();
      signOut({
        callbackUrl: "/api/end-session",
      });
    }
  };
  // console.log(router)

  const useCurrentRoute = () => {
    return router.asPath;
  }
  const currentRoute = useCurrentRoute();
  const routesMaster = [
    { path: `/${router.query["categoryId"]}/master/masterQuestion`, label: 'Question' },
    { path: `/${router.query["categoryId"]}/master/masterGroupUser`, label: 'Group User' },
    { path: `/${router.query["categoryId"]}/master/masterVendor`, label: 'Vendor' },
  ];
  const routesRopa = [
    { path: `/${router.query["categoryId"]}`, label: 'Compliance' },
    { path: `/${router.query["categoryId"]}/RopaList`, label: 'ROPA List' },
    { path: `/${router.query["categoryId"]}/DataMappingList`, label: 'Data Mapping List' },
  ];
  const routesPia = [
    { path: `/${router.query["categoryId"]}/PiaList`, label: 'PIA List' },
  ]
  const routesIncident = [
    { path: `/${router.query["categoryId"]}/IncidentList`, label: 'Incident List' }
  ]
  const routesTria = [
    { path: `/${router.query["categoryId"]}/TriaList`, label: 'TRIA List' }
  ]
  const routesDsr = [
    { path: `/${router.query["categoryId"]}/DsrList`, label: 'Request List' }
  ]

  if (isAdmin) {
    routesPia.push({ path: `/${router.query["categoryId"]}/ReminderTemplatePiaTria`, label: 'Reminder Template' })
    routesIncident.push({ path: `/${router.query["categoryId"]}/ReminderTemplate`, label: 'Reminder Template' })
    routesTria.push({ path: `/${router.query["categoryId"]}/ReminderTemplatePiaTria`, label: 'Reminder Template' })
    routesDsr.push({ path: `/${router.query["categoryId"]}/ReminderTemplate`, label: 'Reminder Template' })
  }
  const dsrFormPath = isAdmin ? `/${router.query["categoryId"]}/DsrList/dsrForm` : `/Form`;
  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            // Sidebar background color:
            // https://github.com/ant-design/ant-design/blob/5.0.0/components/layout/style/index.tsx#L101
            colorBgHeader: sidebarBackgroundColor,
          },
        },
      }}
    >
      <Layout className="min-h-screen">
        <Head>
          <meta key="meta-charset" charSet="utf-8" />
          <meta
            key="meta-viewport"
            name="viewport"
            content="width=device-width, initial-scale=1"
          />
          <link key="favicon" rel="icon" href="/favicon.ico" />
        </Head>

        <nav
          className="bg-[#3788FD] fixed box-content w-full z-10 shadow-md p-[10px]"
        >
          <div className="flex items-center">
            <div
              className="hidden md:block logo grow"
              onClick={() => router.push("/")}
            >
              <img
                src="/adaptist-white-logo.png"
                alt="logo"
                className="cursor-pointer max-w-[200px] mr-[70px] ml-[40px]"
              />
            </div>
            <div className="flex justify-between w-full">
              {/* Category Page */}
              {isCategoryPage && (
                <div className="mr-2 max-w-full">
                  {/* <SearchCategoryNavBar setSearchResults={setSearchResults} searchResults={searchResults} /> */}
                  <SearchResultNav
                    onClick={receiveDataFromChild}
                    searchResults={searchResults}
                  />
                </div>
              )}
              {/* First Sub Category Page */}
              {isSubCategoryPage && (
                <div className="mr-2 max-w-full">
                  <SearchSubCategoryNavBar
                    setSearchResults={setSearchResults}
                    searchResults={searchResults}
                  />
                  <SearchSubCategoryResultNav searchResults={searchResults} />
                </div>
              )}
              {/* Second Sub Category Page */}
              {isChecklistPage && (
                <div className="mr-2 max-w-full">
                  <SearchChecklistNavBar
                    setSearchResults={setSearchResults}
                    searchResults={searchResults}
                  />
                  <SearchChecklistResult searchResults={searchResults} />
                </div>
              )}
              {/* View All files */}
              {isVersePage && (
                <div className="mr-2 max-w-full">
                  <SearchFileNavBar
                    setSearchResults={setSearchResults}
                    searchResults={searchResults}
                  />
                  <SearchFileResult searchResults={searchResults} />
                </div>
              )}
              {isMasterPage && (
                <div className="mr-2 max-w-full"></div>
              )}

              <div className="grid grid-cols-1 lg:grid-cols-auto lg:grid-flow-col lg:grid-rows-1 items-center">
                <div className="grid grid-cols-1 lg:grid-cols-auto lg:grid-flow-col lg:grid-rows-1 items-center">
                  <ul className="lg:flex space-x-4 items-center">
                    <li className="flex items-center">
                      <div className="text-white text-base hidden lg:block cursor-pointer font-semibold fontWeight: '600'">
                        {`Halo, ${displayUserName}`}
                      </div>
                    <div className="ml-8">
                      <button onClick={() => setShowModal(true)}>
                        <div className="px-2 py-1 ">
                          <FontAwesomeIcon icon={faBell} size="lg" color="white"/>
                        </div>
                      </button>
                    </div>
                      {isAdmin && (
                        <div className="ml-8">
                          <button
                            onClick={goToManageUserPage}
                            className="text-white px-2"
                          >
                            <div>
                              <FontAwesomeIcon icon={faUserGear} size="xl" />
                            </div>
                          </button>
                        </div>
                      )}
                      <div className="px-5">
                        <button onClick={handleLogout} className="text-white">
                          <div
                            className="mr-[8.5px] px-2 pt-[3px]"
                          >
                            <FontAwesomeIcon icon={faArrowRightFromBracket} size="xl" />
                          </div>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <NotificationModal show={showModal} onClose={() => setShowModal(false)} />
            </div>
          </div>
        </nav>
        
        
        <Layout>
          <Sider
            width={300}
            className="pb-24 hidden lg:block sider-bar"
          >
            <div onClick={() => router.push("/")} className="ml-6">
              <img
                src="/adaptist-blue-logo.png"
                alt="logo"
                className="cursor-pointer max-w-[250px]"
              />
            </div>
            <Tooltip title={data?.title} placement="right">
              {data?.title.includes("ROPA") && (
                <Link href={`/${categoryId}/RopaSelection`}>
                  <p
                    className="moveLeft p-2 px-4 m-4 text-white font-bold text-base bg-[#3788FD] rounded-[10px] opacity-80"
                  >
                    {data?.title}
                  </p>
                </Link>
              )}
              {!data?.title.includes("ROPA") && (
                <Link href={`/${categoryId}`}>
                  <p
                    className="moveLeft p-2 px-4 m-4 text-white font-bold bg-[#3788FD] rounded-[10px] opacity-80"
                  >
                    {data?.title}
                  </p>
                </Link>
              )}
            </Tooltip>
            <div
              className="m-4 cursor-pointer"
            >
              {firstSubCategories &&
                firstSubCategories.map((firstSub, i) => (
                  <Collapsible
                    setSearchRoutePath={() => setRoutePath("")}
                    searchRoutePath={routePath}
                    open={openAll}
                    title={firstSub.title}
                    routePath={firstSub.routePath}
                    childrenItem={firstSub.children}
                    changeCollapseStatus={changeCollapseStatusByIndex}
                    resetToggle={resetToggleFromButtonState}
                    toggledFlag={toggledFromCollapseOrExpandAll}
                    selectedIndex={selectedChapterIndex}
                    currentIndex={i}
                    key={i}
                  />
                ))}
            </div>

            {/* {data?.title.includes("Master") && (
              <>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold cursor-pointer"
                  onClick={() =>
                    router.push(
                      `/${router.query["categoryId"]}/master/masterQuestion`
                    )
                  }
                >
                  Master Question
                </p>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold cursor-pointer"
                  onClick={() =>
                    router.push(
                      `/${router.query["categoryId"]}/master/masterGroupUser`
                    )
                  }
                >
                  Master Group User
                </p>
              </>
            )} */}
            {data?.title.includes("Master") && (
              <>
                {
                  routesMaster.map((route) => (
                    <p
                      key={route.path}
                      className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer 
                      ${currentRoute.startsWith(route.path) ? 'text-blue-500' : 'text-black'}`}
                      onClick={() => router.push(route.path)}
                    >
                      {route.label}
                    </p>
                  ))
                }
              </>
            )}

            {/* {data?.title.includes("ROPA") && (
              <>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold cursor-pointer"
                  onClick={() =>
                    router.push(
                      `/${router.query["categoryId"]}`
                    )
                  }
                >
                  Compliance
                </p>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold cursor-pointer"
                  onClick={() =>
                    router.push(
                      `/${router.query["categoryId"]}/RopaList`
                    )
                  }
                >
                  ROPA List
                </p>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold cursor-pointer"
                  onClick={() =>
                    router.push(
                      `/${router.query["categoryId"]}/DataMappingList`
                    )
                  }
                >
                  Data Mapping List
                </p>
              </>
            )} */}
            {data?.title.includes("ROPA") && (
              <>
                {
                  routesRopa.map((route) => {
                    const isCompliance = route.label === 'Compliance';
                    const isActive = isCompliance
                      ? currentRoute === route.path
                      : currentRoute.startsWith(route.path);
                    return (
                      <p
                        key={route.path}
                        className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer ${isActive ? 'text-blue-500' : 'text-black'}`}
                        onClick={() => router.push(route.path)}
                      >
                        {route.label}
                      </p>
                    );
                  })}
              </>
            )}

            {/* {data?.title.includes("PIA") && (
              <>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold cursor-pointer"
                  onClick={() =>
                    router.push(
                      `/${router.query["categoryId"]}/PiaList`
                    )
                  }
                >
                  Pia List
                </p>
                {isAdmin && (
                  <p
                    className="mx-10 mt-3 mb-5 text-[#373737] font-semibold cursor-pointer"
                    onClick={() =>
                      router.push(
                        `/${router.query["categoryId"]}/ReminderTemplatePiaTria`
                      )
                    }
                  >
                    Reminder Template
                  </p>
                )}
              </>
            )} */}
            {data?.title.includes("PIA") && (
              <>
                {routesPia.map((route) => (
                  <p
                    key={route.path}
                    className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer 
                    ${currentRoute.startsWith(route.path) ? 'text-blue-500' : 'text-black'}`}
                    onClick={() =>
                      router.push(route.path)
                    }
                  >
                    {route.label}
                  </p>
                ))}
              </>
            )}

            {/* {data?.title.includes("Incident") && (
              <>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer"
                  onClick={() =>
                    router.push(`/${router.query["categoryId"]}/IncidentList`)
                  }
                >
                  Incident List
                </p>
                {isAdmin && (
                  <p
                    className="mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer"
                    onClick={() =>
                      router.push(
                        `/${router.query["categoryId"]}/ReminderTemplate`
                      )
                    }
                  >
                    Reminder Template
                  </p>
                )}
              </>
            )} */}
            {data?.title.includes("Incident") && (
              <>
                {routesIncident.map((route) => (
                  <p
                    key={route.path}
                    className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer 
                    ${currentRoute.startsWith(route.path) ? 'text-blue-500' : 'text-black'}`}
                    onClick={() =>
                      router.push(route.path)
                    }
                  >
                    {route.label}
                  </p>
                ))}
              </>
            )}

            {/* {data?.title.includes("TRIA") && (
              <>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer"
                  onClick={() =>
                    router.push(`/${router.query["categoryId"]}/TriaList`)
                  }
                >
                  Tria List
                </p>
                {isAdmin && (
                  <p
                    className="mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer"
                    onClick={() =>
                      router.push(
                        `/${router.query["categoryId"]}/ReminderTemplatePiaTria`
                      )
                    }
                  >
                    Reminder Template
                  </p>
                )}
              </>
            )} */}
            {data?.title.includes("TRIA") && (
              <>
                {routesTria.map((route) => (
                  <p
                    key={route.path}
                    className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer 
                    ${currentRoute.startsWith(route.path) ? 'text-blue-500' : 'text-black'}`}
                    onClick={() =>
                      router.push(route.path)
                    }
                  >
                    {route.label}
                  </p>
                ))}
              </>
            )}

            {/* {data?.title.includes("Data Subject Right") && (
              <>
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer"
                  onClick={() =>
                    router.push(`/${router.query["categoryId"]}/DsrList`)
                  }
                >
                  Request List
                </p>
                {isAdmin && (
                  <p
                    className="mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer"
                    onClick={() =>
                      router.push(
                        `/${router.query["categoryId"]}/ReminderTemplate`
                      )
                    }
                  >
                    Reminder Template
                  </p>
                )}
                <p
                  className="mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer"
                  onClick={() => {
                    const path = userRole === "Admin"
                      ? `/${router.query["categoryId"]}/DsrList/dsrForm`
                      : `/Form`;
                    router.push(path);
                  }}
                >
                  Dsr Form{" "}
                </p>
              </>
            )} */}
            {data?.title.includes("Data Subject Right") && (
              <>
                {routesDsr.map((route) => (
                  <p
                    key={route.path}
                    className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer 
                    ${currentRoute.startsWith(route.path) && currentRoute !== dsrFormPath ? 'text-blue-500' : 'text-black'}`}
                    onClick={() =>
                      router.push(route.path)
                    }
                  >
                    {route.label}
                  </p>
                ))}
                <p
                  className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer 
                    ${currentRoute === dsrFormPath ? 'text-blue-500' : 'text-black'}`}
                  onClick={() => {
                    router.push(dsrFormPath);
                  }}
                >
                  DSR Form
                </p>
              </>
            )}


            {data?.title.includes("Policy") && (
              <>
                <p
                  className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer 
                    ${currentRoute.startsWith(`/${router.query["categoryId"]}/PrivacyPolicyList`)  ? 'text-blue-500' : 'text-black'}`}
                  onClick={() =>
                    router.push(
                      `/${router.query["categoryId"]}/PrivacyPolicyList`
                    )
                  }
                >
                  Policy Records
                </p>
              </>
            )}
            {data?.title.includes("Consent") && (
              <>
                <p
                  className={`mx-10 mt-3 mb-5 text-[#373737] font-semibold text-base cursor-pointer 
                    ${currentRoute.startsWith(`/${router.query["categoryId"]}/ConsentRecordList`) ? 'text-blue-500' : 'text-black'}`}
                  onClick={() =>
                    router.push(
                      `/${router.query["categoryId"]}/ConsentRecordList`
                    )
                  }
                >
                  Consent Records
                </p>
              </>
            )}
            <button
              className="mx-8 mt-5 mb-5 text-[#373737] underline text-sm font-normal"
              onClick={handleExpandOrCollapseAll}
            >
              Expand / Collapse all
            </button>
          </Sider>

          <Content
            className={`px-7 pt-[130px] ml-[300px]`}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export const WithCategoryLayout = (page: React.ReactElement) => (
  <Authorize>
    <CategoryLayout>{page}</CategoryLayout>
  </Authorize>
);
